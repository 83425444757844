<template>
  <div class="pot">
    <set-assignee
      v-model="assigneeDialog"
      :request-reference="item.reference"
      :current-assignee="item.assignee"
    />
    <q-card
      v-ripple
      class="pot-row cursor-pointer resource-card no-shadow"
      @click.native="view"
    >
      <q-card-section class="row">
        <div class="col tcell">
          {{ item.created_at.date | timezoneFilter('Europe/London') }}
        </div>
        <div class="col type tcell">
          {{ item.type }}
        </div>
        <div class="col tcell">
          {{ mutateStatus(item.status) }}
        </div>
        <div class="col tcell">
          {{ item.reference }}
        </div>
        <div class="col tcell">
          {{ organisation }}
        </div>
        <div class="col tcell">
          {{ requester }}
        </div>
        <div class="col tcell">
          {{ traveller }}
        </div>
        <div
          class="col tcell"
          @click.stop
        >
          {{ assignee }}
          <q-btn
            round
            flat
            dense
            size="sm"
            icon="person_add"
            @click="assigneeDialog = true"
          />
        </div>
      </q-card-section>
    </q-card>

    <!--
      TODO: Investigate the block below, because I can't find where we actually display it - Liam H
    -->

    <q-card class="bg-white pot-card col-xs-12">
      <q-card-section class="card-header">
        <div class="requester">
          {{ requester }}
        </div>
        <div class="reference">
          {{ item.reference }}
        </div>
        <span slot="subtitle">{{ organisation }}</span>
      </q-card-section>
      <q-card-section class="card-main">
        <div>
          Requested
          <span class="type">{{ item.type === 'amendment' ? `an ${item.type}` : `a ${item.type}` }}</span>
          at
          <span class="date">{{ item.created_at.date | timezoneFilter('Europe/London') }}</span>
          for
          <span class="date">{{ requester === traveller ? 'themself' : traveller }}</span>
        </div>
        <q-chip class="status" color="blue">
          {{ item.status }}
        </q-chip>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-chip class="assignee" icon="assignment_ind" color="green">
          {{ assignee }}
        </q-chip>
        <q-btn flat @click="assigneeDialog = true">
          Assign
        </q-btn>
        <q-btn flat @click="view">
          View
        </q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import timezoneFilter from 'utils/timezoneFilter'
import setAssignee from '../../request/view/flow/set-assignee'
export default {
  components: {
    setAssignee
  },
  filters: {
    timezoneFilter
  },
  props: {
    item: {
      type: Object
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      assigneeDialog: false
    }
  },
  computed: {
    requester () {
      const { requester } = this.item
      return requester?.display_name || 'Unknown'
    },
    traveller () {
      const { user, passenger } = this.item
      if (user) return user.display_name
      return passenger?.name || 'Unknown'
    },
    assignee () {
      const { assignee } = this.item
      return assignee?.display_name || 'Unassigned'
    },
    organisation () {
      const { organisation } = this.item
      return organisation?.name || 'Individual'
    }
  },
  methods: {
    mutateStatus (status) {
      switch (status) {
      case 'AMENDED':
        return 'BOOKING AMENDED'
      case 'CANCELLED':
        return 'BOOKING CANCELLED'
      case 'FAILED':
        return 'BOOKING FAILED'
      default:
        return status
      }
    },
    view (tab) {
      if (this.item.type === 'booking' && this.item.status === 'BOOKED') {
        this.$router.push({ query: { booking: this.item.reference }, hash: '/booking' })
      } else {
        this.$router.push(`/request/${this.item.reference}`)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.actions
  height 50px
.actions > *
  margin-top 8px

.pot-row
  display none
.pot-card
  display block
  position: relative

.reference
  color rgba(0,0,0,0.4)
  text-align right

@media (min-width: 768px) {
  .pot-row {
    display flex
    position relative
    margin: 4px

    &:hover {
      .row {
        background #e1e1e1
        cursor pointer
      }
    }

    .row {
      width 100%
      transition background-color 0.1s ease-out
    }
  }
  .pot-card {
    display none!important
  }
}

.card-header
  .requester, .reference
    display inline-block
  .requester
    width 65%
  .reference
    width 35%

.type, .date
  color rgba(0, 0, 0, 0.87)

.status
  margin-top 10px

.assignee
  position absolute
  left 16px

.type
  text-transform capitalize

</style>
