<template>
  <q-page>
    <list :key="$route.params.content" />
  </q-page>
</template>

<script type="text/javascript">
import list from './list'
export default {
  components: {
    list
  }
}
</script>
