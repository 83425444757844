<template>
  <div class="pot-entry-list">
    <div class="top-sheet bg-white shadow-2">
      <q-btn-dropdown :label="$tc(`content_type.${$route.params.content}`)" class="content-dropdown no-shadow bg-white">
        <q-list>
          <q-item v-for="c in contents" :key="c" v-ripple :active="$route.path.includes(c)" clickable @click.native="() => $router.push(`/pots/${c}`)">
            <q-item-section class="text-capitalize">
              {{ $tc(`content_type.${c}`) }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown><span class="dropdown-supplemental">{{ type }}</span>
      <m-resource-table-head :columns="columns" class="table-head" />
    </div>
    <div class="index-list">
      <m-empty-state
        v-if="potEntries.length === 0"
        :style="{padding: '30px', margin: 'auto'}"
        icon="list"
      >
        No items to display
      </m-empty-state>
      <div
        class="row"
      >
        <div
          v-for="b in potEntries"
          :key="b.reference"
          class="row-item col-xs-12"
        >
          <pot-entry-card :item="b" />
        </div>
      </div>
      <q-spinner-dots v-if="loading" slot="message" size="60" class="dots" />
    </div>
  </div>
</template>

<script type="text/javascript">
import potEntryCard from './pot-entry-card'
import { MResourceTableHead, MEmptyState } from 'components/'

export default {
  components: {
    potEntryCard,
    MResourceTableHead,
    MEmptyState
  },
  props: {
    small: {
      type: Boolean
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      contents: ['flight', 'hotel', 'rental', 'ridehailing', 'rail', 'chauffeur', 'concierge', 'lounge', 'parking', 'onwardtravel', 'eurostar', 'carclub', 'drt'],
      columns: ['Requested At', 'Type', 'Status', 'Reference', 'Organisation', 'Requester', 'Traveller', 'Assignee'],
      complete: false,
      page: 0,
      loading: false
    }
  },
  computed: {
    potEntries () {
      let contentType = this.$route.params.content
      if (contentType === 'concierge') {
        contentType = 'other'
      }
      if (contentType === 'taxi' || contentType === 'ridehailing') {
        contentType = ['taxi', 'ridehailing']
      }
      return this.complete ? this.$store.getters['requests/getPotEntries'](contentType) : []
    },
    type () {
      switch (this.$route.name) {
      case 'pots-errors':
        return 'Requests that need actioning'
      case 'pots-amended':
        return 'Amended requests'
      case 'pots-booked-today':
        return 'Completed today'
      default:
        return 'All requests'
      }
    }
  },
  created () {
    this.$store.dispatch('requests/reset')
    this.get()
  },
  methods: {
    get () {
      this.loading = true
      this.page++
      let contentType = this.$route.params.content
      if (this.$route.params.content === 'concierge') {
        contentType = 'other'
      }
      if (this.$route.name === 'pots-errors') {
        return this.$store.dispatch('requests/loadErrors', {
          content: contentType,
          limit: 100,
          page: this.page
        })
          .then(() => {
            this.complete = true
            this.loading = false
          })
      } else if (this.$route.name === 'pots-amended') {
        return this.$store.dispatch('requests/loadAmended', {
          content: contentType,
          limit: 100,
          page: this.page
        })
          .then(() => {
            this.complete = true
            this.loading = false
          })
      } else if (this.$route.name === 'pots-booked-today') {
        return this.$store.dispatch('requests/loadBookedToday', {
          content: contentType,
          limit: 100,
          page: this.page
        })
          .then(() => {
            this.complete = true
            this.loading = false
          })
      }

      return this.$store.dispatch('requests/loadResources', {
        content: contentType,
        limit: 100,
        page: this.page
      })
        .then(() => {
          this.complete = true
          this.loading = false
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  padding 0 1rem
  .row-item:first-child
    margin-top 16px

.resource-card
  max-height 82px
  position relative

.top-sheet
  padding 0 15px
  display flex
  justify-content flex-end
  flex-wrap wrap

.content-dropdown
  font-size 18px
  letter-spacing 1px
  text-transform capitalize
.content-type
  text-transform capitalize

.table-head
  display none

@media (min-width: 768px) {
  .index-list {
    padding 0 3rem
    height calc(100vh - 211px)
    overflow auto
    margin auto
  }

  .top-sheet {
    justify-content flex-start
  }

  .content-dropdown {
    font-size 20px
  }

  .table-head {
    display flex
    flex-basis 100%
    width 100%!important
    padding 1rem 3rem
  }

  .dropdown-supplemental {
    display inline-block
    padding 5px
    font-size 1.2rem
  }
}

</style>
